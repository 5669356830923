import React, { useState } from 'react';

function LoginSection({ onLogin }) {
  const [accountEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  async function handleLogin() {
    try {
      // Send a POST request to your server for authentication
      const response = await fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountEmail, password }),
      });

      if (!response.ok) {
        // If the server responds with an error, handle it
        const errorData = await response.json();
        setError(errorData.error); // Set the error message to display to the user
      } else {
        // If authentication is successful, get the token and store it in local storage
        const { token } = await response.json();
        localStorage.setItem('authToken', token);

        // Call the callback function to notify the parent component
        onLogin();
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred during login.'); // Handle unexpected errors
    }
  }

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h1 className="card-title text-center">Login</h1>
              {error && <p className="text-danger text-center">{error}</p>}
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  value={accountEmail}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="text-center">
                <button className="btn btn-primary" onClick={handleLogin}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginSection;
