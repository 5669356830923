import React, { useState, useEffect } from 'react';
import AccountSection from './components/AccountSection';
import LoginSection from './components/LoginSection';

function App() {
  // Step 1: Initialize the isAuthenticated state based on the presence of the token in local storage
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('authToken'));
  const [loading, setLoading] = useState(true); // To show a loading indicator while checking authentication

  // Step 2: Define a function to handle logout
  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Clear the token from local storage
    setIsAuthenticated(false);
  };

  // Step 3: Use an effect to check authentication status on component mount
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      // Token exists in local storage, consider the user authenticated
      setIsAuthenticated(true);
    }
    setLoading(false); // Loading is complete
  }, []);

  // Step 4: Conditionally render based on authentication status
  return (
    <div className="App">
      {/* Navigation Bar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="/">TradePairs</a>
        <div style={{ marginLeft: 'auto' }}>
          {isAuthenticated && (
            // Move the Logout button to the right end of the navbar
            <button
            className="btn btn-danger"
            style={{ float: 'right', marginRight: '10px' }} // Add marginRight for padding
            onClick={handleLogout}
          >
              Logout
            </button>
          )}
        </div>
      </nav>

      {loading ? (
        // Show a loading indicator while checking authentication
        <p>Loading...</p>
      ) : isAuthenticated ? (
        // Render the AccountSection if the user is authenticated
        <AccountSection isAuthenticated={isAuthenticated} />
      ) : (
        // Render the Login component if the user is not authenticated
        <LoginSection onLogin={() => setIsAuthenticated(true)} />
      )}
    </div>
  );
}

export default App;
